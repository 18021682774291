import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobTimeWrapper = styled.div`
  display: none;

  @media ${media.desktop} {
    display: flex;
    align-items: flex-end;
    margin-top: 7px;
    padding-bottom: 9px;
    border-bottom: 1px solid rgba(101, 138, 200, 0.2);
    min-height: 38px;
  }
`;
