import React, { useContext } from "react";
import styled from "styled-components";
import aEvent from "../../common/aEvent";
import { ReferContext } from "../../contexts/ReferContext";
import { Typo18 } from "../Typography";

interface IWrapper {
  margin: string;
}

const Wrapper = styled.div<IWrapper>`
  display: flex;
  justify-content: center;

  ${({ margin }) => margin};
`;

const Button = styled.button`
  border: none;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }
`;

const Line = styled.span`
  display: block;
  margin-top: 3px;
  height: 2px;
  width: 100%;
  background: #658ac8;
`;

interface Props {
  slug: string;
  margin: string;
}

const ReferButton: React.FC<Props> = (props) => {
  const { slug, margin } = props;

  const { setOpen } = useContext(ReferContext);

  return (
    <Wrapper margin={margin}>
      <Button
        id="job-page-refer-button"
        onClick={() => {
          setOpen(true);

          aEvent({
            ea: "refer-popup-open",
            ec: "Refer Events",
            el: slug,
            ni: false,
          });
        }}
      >
        <Typo18 fontWeight="600" color="#658AC8">
          Refer a friend
        </Typo18>
        <Line />
      </Button>
    </Wrapper>
  );
};

export default ReferButton;
