import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobInfoWrappepr = styled.div`
  padding-top: 26px;

  @media ${media.tablet} {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-top: 18px;
  }
`;

export const JobInfoTitleMobile = styled.div`
  @media ${media.tablet} {
    display: none;
  }
`;

export const JobInfoLeftCol = styled.div`
  @media ${media.tablet} {
    flex-grow: 1;
    width: 0;
    order: 1;
    margin-right: 41px;
  }
`;

export const JobInfoTitleDesktop = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
  }
`;

export const JobInfoRightCol = styled.div`
  @media ${media.tablet} {
    width: 390px;
    order: 2;
    margin-left: 22px;

    @supports (position: sticky) {
      position: sticky;
      top: 99px;
    }
  }

  @media ${media.desktop} {
    width: 430px;
    margin-left: 42px;
  }
`;
