import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import IStrapiImage from "../interfaces/IStrapiImage";
import IJobDetails from "../interfaces/IJobDetails";
import GradientHeroTemplated from "../components/GradientHeroTemplated";
import JobHeader from "../components/JobHeader";
import JobInfo from "../components/JobInfo";
import normalizeJobData from "../common/normalizeJobData";
import "../fragments/Job";
import JobCaseStudies from "../components/JobCaseStudies";
import IClientTestemonial from "../interfaces/IClientTestemonial";
import JobSteps from "../components/JobSteps";
import JobCandidates from "../components/JobCandidates";
import ICandidateTestemonial from "../interfaces/ICandidateTestemonials";
import JobFaq from "../components/JobFaq";
import BottomBar from "../components/BottomBar";
import Footer from "../components/Footer";
import JobMid from "../components/JobMid";
import SendContextProvider from "../contexts/SendContext";
import JobOther from "../components/JobOther";
import JobSEO from "../components/JobSEO";
import TrackingContextProvider from "../contexts/UtmContext";
import IApplyStep from "../interfaces/IApplyStep";
import ApplyStepsContextProvider from "../contexts/ApplyStepsContext";
import ReferContextProvider from "../contexts/ReferContext";

interface IProps {
  data: any;
  pageContext: any;
}

export const SingleJobPage: React.FC<IProps> = (props) => {
  const { data: unformattedData, pageContext } = props;

  const data = normalizeJobData(unformattedData, pageContext);

  const {
    heroText,
    heroImage,
    theGoodStuffHeading,
    defaultTimeToHire,
    caseStudiesHeading,
    aboutSubheading,
    aboutHeading,
    aboutText,
    aboutImage,
    aboutQuote,
    aboutDisclaimer,
    job,
    clientTestemonials,
    stepsHeading,
    stepsImage,
    stepsImageDesktop,
    candidateTestemonials,
    otherJobs,
    techFallbackImage,
    contentFallbackImage,
    applySteps,
    referPopupText,
  }: {
    heroText: string;
    heroImage: IStrapiImage | null;
    theGoodStuffHeading: string;
    defaultTimeToHire: string;
    caseStudiesHeading: string;
    job: IJobDetails;
    aboutSubheading: string;
    aboutHeading: string;
    aboutText: string;
    aboutImage: IStrapiImage;
    aboutQuote: string;
    aboutDisclaimer: string;
    clientTestemonials: IClientTestemonial[];
    stepsHeading: string;
    stepsImage: IStrapiImage | null;
    stepsImageDesktop: IStrapiImage | null;
    candidateTestemonials: ICandidateTestemonial[];
    otherJobs: IJobDetails[];
    techFallbackImage: IStrapiImage | null;
    contentFallbackImage: IStrapiImage | null;
    applySteps: IApplyStep[];
    referPopupText: string;
  } = data;

  const { company, applyUrl, slug } = job;

  return (
    <Layout
      headerLocation="jobs"
      skipFooter={true}
      intercomExtraPadding={true}
    >
      <ApplyStepsContextProvider
        heading={"What to expect once you've applied?"}
        steps={applySteps}
      >
        <JobSEO job={job} />
        <TrackingContextProvider>
          <SendContextProvider slug={slug}>
            <ReferContextProvider slug={slug} content={referPopupText}>
              <GradientHeroTemplated
                text={heroText}
                image={heroImage}
                defaultTimeToHire={defaultTimeToHire}
                job={job}
              />
              <JobHeader job={job} />
              <JobInfo
                job={job}
                theGoodStuffHeading={theGoodStuffHeading}
                techFallbackImage={techFallbackImage}
                contentFallbackImage={contentFallbackImage}
              />
              <div style={{ position: "relative" }}>
                <JobCaseStudies heading={caseStudiesHeading} job={job} />
                <JobMid
                  applyUrl={applyUrl}
                  aboutSubheading={aboutSubheading}
                  aboutHeading={aboutHeading}
                  aboutText={aboutText}
                  aboutImage={aboutImage}
                  aboutQuote={aboutQuote}
                  aboutDisclaimer={aboutDisclaimer}
                  company={company}
                />
                <JobSteps
                  heading={stepsHeading}
                  image={stepsImage}
                  desktopImage={stepsImageDesktop}
                />
                <JobCandidates
                  items={candidateTestemonials}
                  applyUrl={applyUrl}
                />
                <JobFaq faqs={job.faqs} applyUrl={applyUrl} />
                <JobOther jobs={otherJobs} />
                <Footer />
                <BottomBar applyUrl={applyUrl} slug={slug} />
              </div>
            </ReferContextProvider>
          </SendContextProvider>
        </TrackingContextProvider>
      </ApplyStepsContextProvider>
    </Layout>
  );
};

export default SingleJobPage;

export const pageQuery = graphql`
  query CustomJobQuery(
    $slug: String!
    $companyId: String!
    $otherJobIds: [String]
  ) {
    job: strapiJobs(slug: { eq: $slug }) {
      ...Job
      company {
        id
      }
    }
    company: strapiCompanies(strapiId: { eq: $companyId }) {
      ...Company
    }
    strapiJobPage {
      ...JobPage
    }
    faqs: allStrapiFaqs {
      edges {
        node {
          uid: strapiId
          question
          answer
        }
      }
    }
    otherJobs: allStrapiJobs(filter: { strapiId: { in: $otherJobIds } }) {
      edges {
        node {
          ...Job
          company {
            id
            logo {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(width: 100, quality: 100, placeholder: NONE)
                }
              }
            }
            name
          }
        }
      }
    }
  }
`;
