import styled from "styled-components";
import { media } from "../../common/MediaQueries";

interface ITechCardWrapper {
  margin?: number;
}

export const TechCardWrapper = styled.div<ITechCardWrapper>`
  padding: 19px 19px 4px 19px;
  background: #ffffff;
  border: 1px solid rgba(155, 192, 255, 0.38);
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.05);
  border-radius: 15px;

  ${({ margin }) =>
    typeof margin !== "undefined" ? `margin-top: ${margin}px` : ""};

  @media ${media.desktop} {
    padding: 19px 31px 4px 31px;
  }
`;

export const TechCardItemsWrapper = styled.div`
  padding-bottom: 6px;

  @media ${media.desktop} {
    padding-bottom: 2px;
  }
`;

export const TechCardHeading = styled.div`
  padding-bottom: 15px;
  text-transform: uppercase;
  font-variant: small-caps;

  @media ${media.desktop} {
    padding-bottom: 15px;
    font-variation: small-caps;
  }
`;

export const TechCardItems = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const TechCardRow = styled.li`
  display: flex;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  padding-bottom: 10px;

  @media ${media.desktop} {
    padding-bottom: 13px;
  }
`;

export const TechCardImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  flex-shrink: 0;
  background: #efefef;
  border-radius: 6px;
  overflow: hidden;

  & img {
    border-radius: 6px;
  }

  @media ${media.desktop} {
    width: 45px;
    height: 45px;
  }
`;

export const TechCardName = styled.div`
  margin-left: 9px;
  letter-spacing: -0.04em;
  padding-right: 20px;

  @media ${media.desktop} {
    letter-spacing: -0.01em;
    margin-left: 12px;
    font-weight: 700;
    letter-spacing: -0.01em;
  }
`;
