import React, { useEffect, useState } from "react";
import Check from "../../assets/svg/Check";
import { Typo22 } from "../Typography";
import { TimeCheckWrapper, TimeTextWrapper, TimeWrapper } from "./stypes";

interface Props {
  fromGMT: number;
  toGMT: number;
}

const TimeBadge: React.FC<Props> = (props) => {
  const { fromGMT, toGMT } = props;

  const [loading, setLoading] = useState(true);

  const [positive, setPositive] = useState(false);

  useEffect(() => {
    if (typeof fromGMT === "undefined" || typeof toGMT === "undefined") return;

    const offset = new Date().getTimezoneOffset();

    const hours = (offset / 60) * -1;

    setLoading(false);

    setPositive(hours >= fromGMT && hours <= toGMT);
  }, []);

  if (loading || !positive) return null;

  return (
    <TimeWrapper loading={loading} positive={positive}>
      <TimeCheckWrapper>
        <Check />
      </TimeCheckWrapper>
      <TimeTextWrapper>
        <Typo22>Fits your time-zone</Typo22>
      </TimeTextWrapper>
    </TimeWrapper>
  );
};

export default TimeBadge;
