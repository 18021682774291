import React, { useEffect, useRef, useState } from "react";
import Mail from "../../assets/svg/Mail";
import MailClose from "../../assets/svg/MailClose";
import useWindowSize from "../../hooks/windowSize";
import ApplyButton from "../ApplyButton";
import SendEmail from "../SendEmail";
import { Typo14 } from "../Typography";
import {
  BottomBarApplyWrapper,
  BottomBarBottom,
  BottomBarInput,
  BottomBarInputBorder,
  BottomBarInputWrapper,
  BottomBarMessage,
  BottomBarSend,
  BottomBarSmallButton,
  BottomBarTop,
  BottomBarWrapper,
} from "./style";

interface Props {
  applyUrl: string;
  slug: string;
}

const BottomBar: React.FC<Props> = (props) => {
  const { applyUrl, slug } = props;

  const [expand, setExpand] = useState(false);

  const { width: windowWidth } = useWindowSize();

  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) return;

    const el = ref.current as HTMLDivElement;

    if (!expand) {
      el.style.maxHeight = "0px";
      return;
    }

    el.style.maxHeight = `${el.scrollHeight}px`;
  }, [expand, windowWidth]);

  return (
    <SendEmail
      url={`${process.env.GATSBY_API_URL}/content-api/sent-roles/`}
      payload={{ slug }}
      renderContent={(props: any) => {
        const {
          value,
          state,
          valid,
          displayValid,
          onBlur,
          onFocus,
          onChange,
          onSubmit,
        } = props;

        let message;
        let color;

        if (state.success) {
          message = "Role sent.";
          color = "#333";
        } else if (state.error) {
          message = "Something went wrong. Please try again.";
          color = "#FF5070";
        } else if (state.throttled) {
          message = "Sorry. You cannot send any new roles for a while.";
          color = "#444";
        }

        return (
          <BottomBarWrapper>
            <BottomBarTop ref={ref}>
              {!state.success && !state.error && !state.throttled ? (
                <BottomBarInputWrapper loading={state.loading}>
                  <BottomBarInputBorder>
                    <BottomBarInput
                      error={!displayValid}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      placeholder="Email for later"
                    />
                  </BottomBarInputBorder>
                  <BottomBarSend
                    disabled={!valid || state.loading}
                    onClick={onSubmit}
                  >
                    Send
                  </BottomBarSend>
                </BottomBarInputWrapper>
              ) : (
                <BottomBarMessage>
                  <Typo14 color={color}>{message}</Typo14>
                </BottomBarMessage>
              )}
            </BottomBarTop>
            <BottomBarBottom>
              <BottomBarSmallButton onClick={() => setExpand(!expand)}>
                {expand ? <MailClose /> : <Mail />}
              </BottomBarSmallButton>
              <BottomBarApplyWrapper>
                <ApplyButton url={applyUrl} />
              </BottomBarApplyWrapper>
            </BottomBarBottom>
          </BottomBarWrapper>
        );
      }}
    />
  );
};

export default BottomBar;
