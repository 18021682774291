import styled from "styled-components";
import { media } from "../../common/MediaQueries";

interface ITimeWrapper {
  loading: boolean;
  positive?: boolean;
}

export const TimeWrapper = styled.div<ITimeWrapper>`
  display: flex;
  align-items: center;
  min-width: 167px;
  min-height: 26px;
  padding: 2px 11px;
  border-radius: 6px;

  background: ${({ positive }) =>
    positive ? "rgba(157, 237, 94, 0.3);" : "red"};
  ${({ loading }) => (loading ? "background: rgba(200, 200, 200, 0.3);" : "")};

  @media ${media.desktop} {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export const TimeCheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
`;

export const TimeTextWrapper = styled.div`
  margin-left: 6px;

  @media ${media.desktop} {
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    font-variant: small-caps;
  }
`;
