import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobFaqWrapper = styled.div`
  padding-bottom: 40px;
`;

export const JobFaqWrapperHeading = styled.div`
  padding-top: 40px;

  @media ${media.tablet} {
    padding-top: 60px;
  }
`;

export const JobFaqItems = styled.div`
  padding-top: 4px;

  @media ${media.tablet} {
    padding-top: 22px;
  }
`;

export const JobFaqItem = styled.div`
  margin-top: 15px;
  padding: 0 14px;
  background: #ffffff;
  border: 1px solid rgba(155, 192, 255, 0.5);
  border-radius: 15px;

  @media ${media.tablet} {
    margin-top: 24px;
    padding: 0 24px;
  }
`;

export const JobFaqHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;

  & p {
    padding-right: 20px;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #658ac8;
  }

  @media ${media.tablet} {
    padding-top: 18px;
    padding-bottom: 7px;

    & p {
      padding-right: 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;

export const JobFaqMoreWrapper = styled.div`
  dispay: flex;
  justify-items: center;
  align-items: center;
  width: 26px;
  height: 26px;
  background: rgba(101, 138, 200, 0.1);
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: auto;

  @media ${media.tablet} {
    display: none;
  }
`;

export const JobFaqAnswer = styled.div`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01em;

  color: rgba(11, 38, 76, 0.7);
  padding-top: 2px;
  padding-bottom: 20px;

  & :first-child {
    text-indent: 25px;
  }

  &:before {
    position: absolute;
    top: 11px;
    width: 16px;
    height: 1px;
    left: 0;
    display: block;
    content: " ";
    background-color: rgba(11, 38, 76, 0.7);
  }

  @media ${media.tablet} {
    font-size: 16px;

    & :first-child {
      text-indent: 42px;
    }

    &:before {
      top: 11px;
      left: 5px;
      width: 26px;
    }
  }
`;

export const JobFaqApply = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    margin-top: 43px;
    padding-bottom: 60px;
  }
`;
