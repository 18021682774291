import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobCaseStudiesWrapper = styled.div`
  margin-top: 40px;
  padding: 35px 0 33px 0;
  width: 100%;
  overflow: hidden;
  background: rgba(240, 245, 255, 0.6);

  @media ${media.desktop} {
    margin-top: 80px;
    padding: 70px 0;
  }
`;

export const JobCaseStudiesHeading = styled.div`
  padding: 0 27px;
  letter-spacing: -0.01em;
  color: #6e95d7;
  font-weight: 600;

  @media ${media.tablet} {
    color: #658ac8;
    letter-spacing: 0;
    font-weight: 700;
  }
`;

export const JobCaseStudiesMaxWidth = styled.div`
  max-width: 1470px;
  margin: auto;
`;

export const JobCaseStudiesContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: 20px;

  @media ${media.tablet} {
    width: auto;
    margin: 0 -33px;
    padding-top: 53px;
  }
`;
