import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import IStrapiImage from "../../interfaces/IStrapiImage";
import CompanyFeatured from "../CompanyFeatured";
import CompanyOneLiner from "../CompanyOneLiner";
import JobContent from "../JobContent";
import JobContentApply from "../JobContentApply";
import JobFeatured from "../JobFeatured";
import JobSectionWrapper from "../JobSectionWrapper";
import { JobTime } from "../JobTime";
import JobVisa from "../JobVisa";
import { Heading2, Heading20, Typo13 } from "../Typography";
import {
  JobInfoTitleDesktop,
  JobInfoTitleMobile,
  JobInfoWrappepr,
  JobInfoLeftCol,
  JobInfoRightCol,
} from "./styles";

interface Props {
  job: IJobDetails;
  theGoodStuffHeading: string;
  techFallbackImage: IStrapiImage | null;
  contentFallbackImage: IStrapiImage | null;
}

const JobInfo: React.FC<Props> = ({
  job,
  theGoodStuffHeading,
  techFallbackImage,
  contentFallbackImage,
}) => {
  const { company, name, applyUrl } = job;

  const { name: companyName } = company;

  const secondary = company.secondary;

  return (
    <JobSectionWrapper>
      <JobInfoWrappepr>
        <JobInfoTitleMobile>
          <Typo13 color="#1B1B1B" fontWeight="600" as="h2">
            {name} at {companyName}
          </Typo13>
          <CompanyOneLiner company={company} />
        </JobInfoTitleMobile>
        <JobInfoRightCol>
          <JobFeatured
            job={job}
            heading={theGoodStuffHeading}
            techFallbackImage={techFallbackImage}
          />
          <CompanyFeatured company={company} />
        </JobInfoRightCol>
        <JobInfoLeftCol>
          <JobInfoTitleDesktop>
            <Heading20
              color="#1B1B1B"
              fontWeight="700"
              as="h1"
              style={{ paddingLeft: "1px" }}
            >
              {name} at {companyName}
            </Heading20>
            <JobTime job={job} />
            <CompanyOneLiner company={company} />
          </JobInfoTitleDesktop>
          <JobContent
            items={job.main}
            hasMore={secondary.length > 0}
            contentFallbackImage={contentFallbackImage}
          />
          {job.main.length > 0 && secondary.length > 0 && (
            <JobContentApply applyUrl={applyUrl} />
          )}
          <JobContent
            secondary={true}
            items={secondary}
            hasMore={false}
            contentFallbackImage={contentFallbackImage}
          />
          <JobVisa job={job} />
        </JobInfoLeftCol>
      </JobInfoWrappepr>
    </JobSectionWrapper>
  );
};

export default JobInfo;
