import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const CompanyFeturedWrapper = styled.div`
  margin-top: 15px;
  padding: 19px 31px 17px 32px;
  border: 1px solid rgba(155, 192, 255, 0.5);
  border-radius: 15px;

  @media ${media.tablet} {
    padding: 40px 14px;
  }
`;

export const CompanyFeaturedHeading = styled.div`
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    letter-spacing: -0.02em;
  }
`;

export const CompanyFeaturedItems = styled.ul`
  padding-top: 8px;

  @media ${media.tablet} {
    display: flex;
    justify-content: center;
    padding-top: 34px;
  }
`;

export const CompanyFeaturedItem = styled.li`
  display: flex;
  align-items: center;
  padding-bottom: 3px;

  &:first-child {
    padding-top: 0;
  }

  @media ${media.tablet} {
    display: block;
    padding: 0 2px;
    text-align: center;
    width: 33.333%;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

export const CompanyFeaturedBig = styled.div`
  margin-right: 7px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  font-family: proxima-nova, sans-serif;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #658ac8;

  @media ${media.tablet} {
    margin: 0;
    font-size: 50px;
    line-height: 40px;
  }
`;

export const CompanyFeaturedSmall = styled.div`
  margin-top: 4px;

  @media ${media.tablet} {
    margin-top: 10px;
    opacity: 0.7;
    font-weight: 600;
  }
`;
