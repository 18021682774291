import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const CompanyCheatWrapper = styled.div`
  padding: 40px 0 0 0;

  @media ${media.tablet} {
    padding: 0;
  }
`;

export const CompanyCheatInner = styled.div`
  padding-bottom: 31px;

  @media ${media.tablet} {
    display: flex;
    padding-bottom: 0;
  }
`;

export const CompanyCheatContent = styled.div`
  line-height: 1.21;
  color: rgba(11, 38, 76, 0.7);
  padding-top: 5px;

  @media ${media.tablet} {
    max-width: 741px;
    margin-right: auto;
    padding-top: 0;
  }
`;

export const CompanyCardsWrapper = styled.div`
  margin-top: 20px;

  @media ${media.tablet} {
    display: none;
  }
`;

export const CompanyLinksMobileWrapper = styled.div`
  @media ${media.tablet} {
    display: none;
  }
`;

export const CompanyCheatApplyWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    margin-top: 32px;
  }
`;

export const CompanyCheatHeadingWrapper = styled.div`
  @media ${media.tablet} {
    display: none;
  }
`;
