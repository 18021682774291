import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobFeaturedWrapper = styled.div`
  margin-top: 23px;
  padding: 19px 17px 26px 17px;
  background: #ffffff;
  border: 1px solid rgba(155, 192, 255, 0.5);
  border-radius: 15px;

  @media ${media.tablet} {
    margin-top: 0;
    border-radius: 0;
    padding: 26px 20px 18px 21px;
  }
`;

export const JobFeaturedHeading = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;

    & > h2 {
      font-weight: 800;
      letter-spacing: -0.003em;
      font-size: 23px;
      line-height: 29px;
      text-align: center;
      text-decoration-line: underline;
      color: #658ac8;
      text-transform: uppercase;
    }
  }
`;

export const JobFeaturedItems = styled.ul`
  display: block;

  @media ${media.tablet} {
    margin-top: 19px;
  }
`;

interface IJobFeaturedItem {
  first?: boolean;
  destopSmallBorder?: boolean;
}

export const JobFeaturedItem = styled.li<IJobFeaturedItem>`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: ${({ first }) => (first ? 0 : 14)}px;
  padding-left: 20px;
  padding-right: 12px;

  ${({ first }) => first && "margin-top: 0;"}

  @media ${media.tablet} {
    justify-content: center;
    margin-top: ${({ destopSmallBorder }) =>
      destopSmallBorder ? "9px" : "18px"};
  }
`;

export const JobFeaturedItemPart = styled.div`
  letter-spacing: -0.01em;
  font-weight: 500;

  &:first-child {
    margin-right: 4px;
    color: #0b264c;
  }

  &:nth-child(2) {
    color: #6e95d7;
  }

  @media ${media.tablet} {
    width: 100%;
    text-align: center;

    &:first-child {
      margin-right: 0;
    }

    &:first-child > * {
      color: #1b1b1b;
      opacity: 0.4;
      text-transform: uppercase;
      font-weight: 700;
    }

    &:nth-child(2) {
      margin-top: 7px;
    }

    &:nth-child(2) > * {
      letter-spacing: 0;
      color: #658ac8;
      font-weight: 600;
    }
  }
`;

export const JobFeaturedDots = styled.span`
  @media ${media.tablet} {
    display: none;
  }
`;

export const JobFeaturedSalary = styled.p`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #0b264c;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    && {
      display: block;
      margin-top: 1px;
      font-size: 24px;
      line-height: 31px;
      color: #0b264c;
      font-weight: 600;
      margin-right: 0;
    }
  }
`;

export const JobFeaturedSalaryString = styled.span`
  margin-right: 4px;
  display: block;

  @media ${media.tablet} {
    margin-right: 0;
  }
`;

export const JobFeaturedEquity = styled.span`
  display: block;
  color: #0b264c;
  letter-spacing: -0.01em;
  font-weight: 600;
  line-height: 22px;

  @media ${media.tablet} {
    && {
      margin-left: 0;
      display: block;
      font-size: 14px;
      line-height: 31px;
      font-style: italic;
      letter-spacing: 0;
    }
  }
`;

export const ApplyBtnWrapper = styled.div`
  margin-top: 25px;
`;
