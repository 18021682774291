import React from "react";
import IClientTestemonial from "../../interfaces/IClientTestemonial";
import ICompanyDetails from "../../interfaces/ICompanyDetails";
import IStrapiImage from "../../interfaces/IStrapiImage";
import CompanyCard from "../CompanyCard";
import CompanyCheat from "../CompanyCheat";
import CompanyDay from "../CompanyDay";
import CompanyLinks from "../CompanyLinks";
import JobAbout from "../JobAbout";
import JobSectionWrapper from "../JobSectionWrapper";
import { Heading19 } from "../Typography";
import {
  JobMidCompanyCardWrapper,
  JobMidTestemonialWrappers,
  JobMidCompanyWrapper,
  JobMidWrapper,
  JobMidStickyWrapper,
  JobMidCompanySticky,
  JobMidCompanyLinks,
  JobMidCompanyCheatHeading,
  JobMidCompanyCheatHeadingLinks,
} from "./styles";

interface Props {
  applyUrl: string;
  aboutSubheading: string;
  aboutHeading: string;
  aboutText: string;
  aboutQuote: string;
  aboutImage: IStrapiImage;
  aboutDisclaimer: string;
  company: ICompanyDetails;
  clientTestemonials?: IClientTestemonial[]
}

const JobMid: React.FC<Props> = (props) => {
  const {
    applyUrl,
    aboutSubheading,
    aboutHeading,
    aboutText,
    aboutQuote,
    aboutImage,
    aboutDisclaimer,
    company,
  } = props;

  const {
    name,
    companyCheatSheet,
    finItems,
    teamItems,
    cultureHeading,
    cultureText,
    links,
  } = company;

  const hasCultureBlock = cultureHeading && cultureText ? true : false;

  return (
    <JobMidWrapper>
      <JobAbout
        subheading={aboutSubheading}
        heading={aboutHeading}
        text={aboutText}
        image={aboutImage}
        quote={aboutQuote}
        disclaimer={aboutDisclaimer}
        applyUrl={applyUrl}
        hasBorder={hasCultureBlock}
      />
      <JobSectionWrapper>
        {companyCheatSheet && (
          <JobMidCompanyCheatHeadingLinks>
            <JobMidCompanyCheatHeading>
              <Heading19 color="#658AC8" as="h2">
                {`${name} Cheat Sheet`}
              </Heading19>
            </JobMidCompanyCheatHeading>
            {links.length > 0 && (
              <JobMidCompanyLinks>
                <CompanyLinks links={links} />
              </JobMidCompanyLinks>
            )}
          </JobMidCompanyCheatHeadingLinks>
        )}
        {(companyCheatSheet || cultureText) && (
          <JobMidStickyWrapper>
            <JobMidCompanyWrapper>
              <CompanyCheat applyUrl={applyUrl} company={company} />
              <CompanyDay company={company} />
            </JobMidCompanyWrapper>
            <JobMidCompanySticky>
              <JobMidCompanyCardWrapper
                extraPaddingDesktop={
                  !companyCheatSheet && cultureText ? true : false
                }
              >
                {finItems.length > 0 && (
                  <CompanyCard
                    first={true}
                    heading={"Financial Background"}
                    items={finItems}
                  />
                )}
                {teamItems.length > 0 && (
                  <CompanyCard heading={"Team stats"} items={teamItems} />
                )}
              </JobMidCompanyCardWrapper>
            </JobMidCompanySticky>
          </JobMidStickyWrapper>
        )}
      </JobSectionWrapper>
    </JobMidWrapper>
  );
};

export default JobMid;
