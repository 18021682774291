import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const ApplySmallWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 250px;
    margin: 0 auto;
  }

  @media ${media.desktop} {
    width: 307px;
  }
`;

export const ApplySmallSendWrapper = styled.div`
  margin-top: 24px;
  padding-bottom: 3px;
  border-bottom: 2px solid #658ac8;
  font-variant: small-caps;
  text-transform: uppercase;
  cursor: pointer;
`;
