import React, { useContext } from "react";
import decorateUrl from "../../common/decorateUrl";
import { SendContext } from "../../contexts/SendContext";
import { UtmContext } from "../../contexts/UtmContext";
import ApplyButton from "../ApplyButton";
import { Typo14 } from "../Typography";
import { ApplySmallSendWrapper, ApplySmallWrapper } from "./styles";

interface Props {
  url: string;
}

const JobContentApplySmall: React.FC<Props> = ({ url }) => {
  const { setOpen: setPopupOpen } = useContext(SendContext);

  const tracking = useContext(UtmContext);

  const decoratedUrl = decorateUrl(url, tracking);

  return (
    <ApplySmallWrapper>
      <ApplyButton url={decoratedUrl} />
      <ApplySmallSendWrapper>
        <Typo14
          onClick={() => setPopupOpen(true)}
          color="#658AC8"
          fontWeight="600"
        >
          Send Role to Email
        </Typo14>
      </ApplySmallSendWrapper>
    </ApplySmallWrapper>
  );
};

export default JobContentApplySmall;
