import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

export const ExpadndableWrapper = styled.div`
  background: #ffffff;
`;

interface IExpadndableHeading {
  expandableOnDesktop?: boolean;
}

export const ExpadndableHeading = styled.div<IExpadndableHeading>`
  cursor: pointer;

  ${({ expandableOnDesktop }) =>
    !expandableOnDesktop
      ? `@media ${media.tablet}{
            pointer-events: none; 
        }`
      : ""}
`;
interface IExpandableMaxHeight {
  allwaysOpen?: boolean;
  expandableOnDesktop?: boolean;
}

export const ExpandableMaxHeight = styled.div<IExpandableMaxHeight>`
  max-height: ${({ allwaysOpen }) => (allwaysOpen ? "none" : "0")};
  transition: max-height 300ms ease-out;
  overflow: hidden;

  @media ${media.tablet} {
    ${({ expandableOnDesktop }) =>
      !expandableOnDesktop && "max-height: none !important;"}
  }
`;
