import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const CompanyDayWrapper = styled.div`
  padding-top: 20px;

  @media ${media.tablet} {
    padding-top: 60px;
  }
`;

export const CompanyDayHeading = styled.div`
  @media ${media.tablet} {
    padding-bottom: 24px;
  }
`;

export const CompanyDaySubheading = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;

  @media ${media.tablet} {
    margin-top: -5px;
    margin-bottom: 43px;
    opacity: 0.5;
  }
`;

export const CompanyDayTextWrapper = styled.div`
  font-size: 14px;
  line-height: 1.142;
  color: #0b264c;
  margin-bottom: 25px;

  @media ${media.tablet} {
    max-width: 741px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.375;
    letter-spacing: -0.01em;
  }
`;
