import React from "react";
import IJobDetails from "../../interfaces/IJobDetails";
import { JobTimeWrapper } from "./styles";
import { Typo12 } from "../Typography";
import TimeBadge from "../TimeBadge";
import formatGMT from "../../common/formatGMT";

interface Props {
  job: IJobDetails;
}

export const JobTime: React.FC<Props> = (props) => {
  const { job } = props;

  const {
    timezoneCategory,
    timezoneGMTMax,
    timezoneGMTMin,
    timezoneFormatted,
  } = job;

  const timeFrom = timezoneCategory ? timezoneCategory.fromGMT : timezoneGMTMin;
  const timeTo = timezoneCategory ? timezoneCategory.toGMT : timezoneGMTMax;

  const hasValid =
    typeof timeFrom !== "undefined" &&
    typeof timeTo !== "undefined" &&
    timeFrom !== null &&
    timeTo !== null;

  if (!hasValid) return null;

  return (
    <JobTimeWrapper>
      {timezoneCategory || timezoneFormatted ? (
        <Typo12
          color="#0B264C"
          style={{ marginRight: "14px", fontFamily: "Montserrat, sans-serif" }}
        >
          Recommended timezone:{" "}
          {timezoneCategory ? timezoneCategory.name : timezoneFormatted}
        </Typo12>
      ) : (
        <Typo12
          color="#0B264C"
          style={{ marginRight: "14px", fontFamily: "Montserrat, sans-serif" }}
        >
          Recommended timezone: {formatGMT([timeFrom, timeTo])}
        </Typo12>
      )}
      <TimeBadge fromGMT={timeFrom} toGMT={timeTo} />
    </JobTimeWrapper>
  );
};
