import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobCandidatesBg = styled.div`
  background: rgba(240, 245, 255, 0.6);
`;

export const JobCandidatesWrapper = styled.div`
  padding: 30px 0;

  @media ${media.desktop} {
    padding: 70px 106px 63px 106px;
    max-width: 1536px;
    margin: 0 auto;
  }
`;

export const JobCandidatePadding = styled.div`
  flex: 1 0 250px;
  padding: 0 8px;

  @media ${media.tablet} {
    flex: 0 0 30%;
    padding: 0 12px;
  }

  @media ${media.desktop} {
    padding: 0 20px;
  }
`;

export const JobCandidateItem = styled.div`
  padding: 10px;
  padding-bottom: 15px;
  background: #ffffff;
  box-shadow: 5.82902px 0px 18.1347px rgba(101, 138, 200, 0.08);
  border-radius: 5px;

  @media ${media.tablet} {
    width: auto;
    padding: 18px 18px 27px 18px;
    border-radius: 10px;
  }

  @media ${media.desktop} {
    padding: 18px 27px 27px 27px;
  }
`;

export const JobCandidateImage = styled.div`
  border-radius: 9px;
  overflow: hidden;

  & img {
    border-radius: 9px;
  }

  & .gatsby-image-wrapper {
    display: block !important;
  }
`;

export const JobCandidateName = styled.div`
  margin-top: 17px;
  letter-spacing: -0.01em;
  font-variant: small-caps;
  text-transform: uppercase;

  @media ${media.tablet} {
    margin-top: 22px;
    letter-spacing: -0.002em;
    font-variant: none;
    text-transform: none;
  }
`;

export const JobCandidateQuote = styled.div`
  margin-top: 17px;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(11, 38, 76, 0.6);

  @media ${media.tablet} {
    margin-top: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
`;

export const JobCandidateApply = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    margin-top: 68px;
  }
`;
