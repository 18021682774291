import React, { useContext } from "react";
import decorateUrl from "../../common/decorateUrl";
import { formatSalaryNumber } from "../../common/utils";
import { UtmContext } from "../../contexts/UtmContext";
import IJobDetails from "../../interfaces/IJobDetails";
import IStrapiImage from "../../interfaces/IStrapiImage";
import ApplyButton from "../ApplyButton";
import ReferButton from "../ReferButton";
import TechCard from "../TechCard";
import { Typo24, Typo25, Typo27, Typo41 } from "../Typography";
import {
  ApplyBtnWrapper,
  JobFeaturedHeading,
  JobFeaturedItem,
  JobFeaturedItemPart,
  JobFeaturedSalary,
  JobFeaturedWrapper,
  JobFeaturedItems,
  JobFeaturedEquity,
  JobFeaturedDots,
  JobFeaturedSalaryString,
} from "./styles";

interface IApplyButton {
  applyUrl: string;
}

const Button: React.FC<IApplyButton> = (props) => {
  const { applyUrl } = props;

  const tracking = useContext(UtmContext);

  const decoratedUrl = decorateUrl(applyUrl, tracking);

  return (
    <ApplyBtnWrapper>
      <ApplyButton url={decoratedUrl} />
    </ApplyBtnWrapper>
  );
};

interface Props {
  job: IJobDetails;
  heading: string | null;
  techFallbackImage: IStrapiImage | null;
}

const JobFeatured: React.FC<Props> = (props) => {
  const { job, heading, techFallbackImage } = props;

  const { company, applyUrl, salaryAggregation, hasEquity, slug } = job;

  const { theGoodStuff: companyGoodStuff } = company;

  const salaryString = formatSalaryNumber(job, false);

  const theGoodStuff = companyGoodStuff;

  const salaryHeading =
    salaryAggregation === "year"
      ? "Annual salary"
      : salaryAggregation === "hour"
      ? "Hourly salary"
      : "Monthly salary";

  return (
    <JobFeaturedWrapper>
      <JobFeaturedHeading>
        <h2>{heading || "The Good Stuff"}</h2>
      </JobFeaturedHeading>
      <JobFeaturedItems>
        <JobFeaturedItem first={true}>
          <JobFeaturedItemPart>
            <Typo25 color="#0B264C" fontWeight="500">
              {salaryHeading}
              <JobFeaturedDots>:</JobFeaturedDots>
            </Typo25>
          </JobFeaturedItemPart>
          <JobFeaturedItemPart>
            <JobFeaturedSalary>
              <JobFeaturedSalaryString>{salaryString}</JobFeaturedSalaryString>
              {hasEquity && <JobFeaturedEquity>+ EQUITY</JobFeaturedEquity>}
            </JobFeaturedSalary>
          </JobFeaturedItemPart>
        </JobFeaturedItem>
        {theGoodStuff.map((goodStuff, index) => {
          const { heading, value } = goodStuff;

          return (
            <JobFeaturedItem
              key={index}
              destopSmallBorder={index === 0 && hasEquity ? true : false}
            >
              <JobFeaturedItemPart>
                <Typo25>
                  {heading}
                  {heading.indexOf(":") !== -1 || !value ? null : (
                    <JobFeaturedDots>:</JobFeaturedDots>
                  )}
                </Typo25>
              </JobFeaturedItemPart>
              {value && (
                <JobFeaturedItemPart>
                  <Typo41>{value}</Typo41>
                </JobFeaturedItemPart>
              )}
            </JobFeaturedItem>
          );
        })}
      </JobFeaturedItems>
      <Button applyUrl={applyUrl} />
      <ReferButton slug={slug} margin="margin-top: 15px;" />
      <TechCard margin={25} job={job} techFallbackImage={techFallbackImage} />
    </JobFeaturedWrapper>
  );
};

export default JobFeatured;
