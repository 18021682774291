import styled from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobsOtherWrapper = styled.div`
  margin-top: 30px;

  @media ${media.desktop} {
    padding-bottom: 75px;
  }
`;

export const JobsOtherHeading = styled.div`
  text-transform: uppercase;
  padding-bottom: 12px;

  @media ${media.tablet} {
    font-weight: 700;
  }
`;
