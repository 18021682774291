import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

export const JobAboutWrapper = styled.div`
  padding-top: 40px;

  @media ${media.tablet} {
    padding-top: 0;
    margin-top: 40px;
    text-align: center;
  }

  @media ${media.tablet} {
    order: 9999;
  }
`;

interface IJobAboutBorder {
  hasBorder: boolean;
}

export const JobAboutBorder = styled.div<IJobAboutBorder>`
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      @media ${media.tablet} {
        border-top: 1px solid rgba(101, 138, 200, 0.2);
        padding-top: 55px;
      }
    `}
`;

export const JobAboutSubheading = styled.div`
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    font-weight: 600;
    letter-spacing: 0.0032em;
  }
`;

export const JobAboutHeading = styled.div`
  margin-top: 10px;
  font-weight: 600;

  @media ${media.tablet} {
    margin-top: 32px;
    font-weight: 700;
  }
`;

export const JobAboutText = styled.div`
  margin-top: 19px;
  font-size: 14px;
  line-height: 1.571;
  color: #0b264c;
  letter-spacing: -0.01em;

  @media ${media.tablet} {
    max-width: 788px;
    margin: 40px auto 0 auto;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0;
  }
`;

export const JobAboutQuote = styled.div`
  margin-top: 70px;

  @media ${media.tablet} {
    margin-top: 57px;
  }
`;

export const JobAboutImage = styled.div`
  margin: 10px auto 0 auto;
  width: 100%;
  max-width: 453px;

  & .gatsby-image-wrapper {
    display: block;
  }

  @media ${media.tablet} {
    max-width: 550px;
  }
`;

export const JobAboutDisclaimer = styled.div`
  margin-top: 21px;

  @media ${media.tablet} {
    margin-top: 14px;
  }
`;

export const JobAboutApplyWrapper = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    margin-top: 36px;
  }
`;
